import { Box, TextField, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import PrintReceiptTable from "../DataGrid/PrintReceiptTable";
import React,{useState} from "react";

const PrintReceiptPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [ob,setOb] = useState("");

  return (
    <Box m="20px">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Header
          title="Receipt Printing"
          subtitle="Manage The Receipt Printing Of The Customers"
        />
        <TextField
          margin="dense"
          label="Old Balance"
          name="ob"
          value={ob}
          onChange={(e)=> setOb(e.target.value)}
        />
      </div>
      <PrintReceiptTable ob={ob}/>
    </Box>
  );
};

export default PrintReceiptPage;
