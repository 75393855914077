import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Skv80OrdersTable from "../DataGrid/Skv80OrdersTable";

const Skv80Orders = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      <Header
        title="SKV?80 Orders"
        subtitle="Manage The SKV?80 Orders"
      />
      <Skv80OrdersTable orders="pending"/>
    </Box>
  );
};

export default Skv80Orders;
