export const insertedBy = () => {
  return parseInt(sessionStorage.getItem("userId"));
};

export const isReviewed = () => {
  // eslint-disable-next-line eqeqeq
  if (sessionStorage.getItem("userType") == "1") {
    return 1;
  } else {
    return 0;
  }
};
