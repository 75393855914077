import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import OverviewModal from "../DataGrid/OverviewModal";

const OverViewPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      <Header title="Orders Over View" subtitle="Manage The Order Over View" />
      <OverviewModal />
    </Box>
  );
};

export default OverViewPage;
