import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import DateReceiptTable from "../DataGrid/DateReceiptTable";

const DateReceiptPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      <Header title="Date Wise Receipt Printing" subtitle="Manage The Date Wise Receipt Printing Of The Customers" />
      <DateReceiptTable />
    </Box>
  );
};

export default DateReceiptPage;
