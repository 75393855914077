import React,{useEffect, useState} from 'react';
import { PieChart,pieArcLabelClasses  } from '@mui/x-charts/PieChart';

export default function PieActiveArc() {
    const [gstOrders,setGstOrders] = useState(0);
    const [skv5,setSkv5] = useState(0);
    const [skv80,setSkv80] = useState(0);

    useEffect(()=>{
        setGstOrders(localStorage.getItem("gstOrders"));
        setSkv5(localStorage.getItem("skv80Orders"));
        setSkv80(localStorage.getItem("skv5Orders"));
    },[]);

    const data = [
        { id: 0, value: gstOrders, label: 'GST Orders' },
        { id: 1, value: skv5, label: 'SKV?80 Orders' }, 
        { id: 2, value: skv80, label: 'SKV/5 Orders' },
      ];
  return (
    <PieChart
      series={[
        {
          data,
          arcLabel: (data) => `${data.value}`,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'white',
          fontWeight: 'bold',
        },
      }}
      height={200}
    />
  );
}