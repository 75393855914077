// import { Box } from "@mui/material";
// import Header from "../../components/Header";
// import LineChart from "../../components/LineChart";
// import React,{useState} from "react";

// const Line = () => {
//   return (
//     <Box m="20px">
//       <Header title="Line Chart" subtitle="Simple Line Chart" />
//       <Box height="75vh">
//         <LineChart />
//       </Box>
//     </Box>
//   );
// };

// export default Line;

import {
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
} from "@mui/material";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import React, { useState } from "react";

const Line = () => {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [yearChecked, setYearChecked] = useState(false);
  const [yearMonthChecked, setYearMonthChecked] = useState(false);

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
  };

  // Generate an array of the last 10 years
  const years = Array.from({ length: 10 }, (_, index) => currentYear - index);

  // Generate month options
  const months = Array.from({ length: 12 }, (_, index) => index + 1);

  return (
    <Box m="20px">
      {/* <Header title="Line Chart" /> */}
      <Box display="flex" justifyContent="space-around" mb={2}>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Checkbox
            checked={yearChecked}
            onChange={(event) => setYearChecked(event.target.checked)}
            color="primary"
          />
          <p>Year Transactions</p>
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Checkbox
            checked={yearMonthChecked}
            onChange={(event) => setYearMonthChecked(event.target.checked)}
            color="primary"
          />
          <p>Year And Month Transactions</p>
        </div>
        <div>
          {yearMonthChecked && (
            <>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel>Year</InputLabel>
                <Select value={year} onChange={handleYearChange}>
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>{" "}
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel>Month</InputLabel>
                <Select value={month} onChange={handleMonthChange}>
                  {months.map((month) => (
                    <MenuItem key={month} value={month}>
                      {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </div>
        {yearChecked && (
          <div>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel>Year</InputLabel>
              <Select value={year} onChange={handleYearChange}>
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
      </Box>
      <Box height="75vh">
        {yearChecked && <LineChart year={year} />}
        {(yearMonthChecked || (!yearMonthChecked && !yearChecked)) && (
          <LineChart year={year} month={month} />
        )}
      </Box>
    </Box>
  );
};

export default Line;
