/* eslint-disable no-unused-vars */
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import CustomerTable from "../DataGrid/CustomerTable";

const CustomerRegPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      <Header title="Customer Registration" subtitle="Manage The Customer Registration" />
      <CustomerTable />
    </Box>
  );
};

export default CustomerRegPage;
