/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper"; // Import Paper component
import ForgotPassword from "../pages/ForgotPassword";
import "./SignIn.css"; // Import the CSS file
import { credentialError, credentialSuccess } from "../support/sweetAlert";
import { encryptData } from "../support/encryption";
import { baseURL } from "../support/baseURL";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.office.com/">
        SKV Silvers APPLICATION
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignIn({ handleLogin }) {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (data.get("email") === "") {
      setEmailError("Please Enter The Email ID");
    } else {
      setEmailError("");
    }
    if (data.get("password") === "") {
      setPasswordError("Please Enter The Password");
    } else {
      setPasswordError("");
    }
    if (data.get("email") !== "" && data.get("password") !== "") {
      handleAuthentication(data.get("email"), data.get("password"));
    } else {
      setError("Please Enter The Required Fields");
    }
  };

  const handleAuthentication = async (phone_number, password) => {
    const responseData = await axios.post(`${baseURL}/users/checkUser`, {
      phone_number: phone_number,
      password: password,
    });
    if (responseData.data.mStatus === 200) {
      sessionStorage.setItem("LoggedInPhn", encryptData(phone_number));
      sessionStorage.setItem("LoggedInPassword", encryptData(password));
      sessionStorage.setItem(
        "LoggedInUserName",
        encryptData(responseData.data.mData.name)
      );
      sessionStorage.setItem(
        "email",
        encryptData(responseData.data.mData.email)
      );
      sessionStorage.setItem("userType", responseData.data.mData.user_type);
      sessionStorage.setItem("userId", responseData.data.mData.user_id);
      /** Success Message For Login */
      credentialSuccess();
      // Pass phone_number to handleLogin
      handleLogin(phone_number);
      navigate("/dash");
    }
    if (responseData.data.mStatus === 202) {
      /** Sweet Alert For Credential Error */
      credentialError();
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <div className="signin-background">
        {!showForgotPassword && (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Paper
              elevation={6}
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                padding: "20px",
              }}
            >
              <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Login
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Phone Number"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <p style={{ color: "red" }}>{emailError}</p>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    id="password"
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />
                  <p style={{ color: "red" }}>{passwordError}</p>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Sign In
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      {/* <Link component="button" variant="body2" onClick={handleForgotPasswordClick} disabled={showForgotPassword}>
                        Forgot Password
                      </Link> */}
                    </Grid>
                    <Grid item></Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
            {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
          </Container>
        )}
        {showForgotPassword && <ForgotPassword />}
      </div>
    </ThemeProvider>
  );
}
