import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import UserRegistrationTable from "../../scenes/DataGrid/UserRegistrationTable";

const UserReg = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      <Header
        title="User Registration"
        subtitle="Manage The User Registration"
      />
      <UserRegistrationTable />
    </Box>
  );
};

export default UserReg;
