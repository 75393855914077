import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Skv5OrdersTable from "../DataGrid/Skv5OrdersTable";

const Skv5Orders = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      <Header
        title="SKV/5 Orders"
        subtitle="Manage The SKV/5 Orders"
      />
      <Skv5OrdersTable orders="pending"/>
    </Box>
  );
};

export default Skv5Orders;
