/* eslint-disable eqeqeq */
import Swal from "sweetalert2";
import axios from "axios";
import { insertedBy } from "./sessionUserName";
import { baseURL } from "./baseURL";

/** Delete SKV 5 Order Based On Order ID*/
export const handleSKV5OrdersDelete = async (
  rows,
  orderId,
  fetchSKV5OrdersData
) => {
  // Show confirmation dialog
  let responseSts = true;
  const row = rows.find((row) => row.order_id === orderId);
  if (sessionStorage.getItem("userType") == "1") {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteValue = await axios.post(
            `${baseURL}/orders/deleteOrders`,
            {
              orderId: orderId,
              is_Delete: 1,
            }
          );
          if (deleteValue) {
            // If deletion is successful, show success message
            Swal.fire({
              title: "Deleted!",
              text: `${orderId} - Order Data Successfully Deleted`,
              icon: "success",
            });

            fetchSKV5OrdersData();
            // Refresh data if needed
          } else {
            // If deletion is not successful
            Swal.fire({
              title: "Error!",
              text: "No Response Comes From Backend",
              icon: "error",
            });

            fetchSKV5OrdersData();
          }
        } catch (error) {
          // If an error occurs during deletion
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
          });
        }
      }
    });
  } else {
    if (row.inserted_by == insertedBy()) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const deleteValue = await axios.post(
              `${baseURL}/orders/deleteOrders`,
              {
                orderId: orderId,
                is_Delete: 1,
              }
            );
            if (deleteValue) {
              // If deletion is successful, show success message
              Swal.fire({
                title: "Deleted!",
                text: `${orderId} - Order Data Successfully Deleted`,
                icon: "success",
              });

              // Refresh data if needed
              fetchSKV5OrdersData();
            } else {
              // If deletion is not successful
              Swal.fire({
                title: "Error!",
                text: "No Response Comes From Backend",
                icon: "error",
              });

              /** Refresh The List Data */
              fetchSKV5OrdersData();
            }
          } catch (error) {
            // If an error occurs during deletion
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
            });
          }
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You Can't Delete The Data",
      });
    }
  }

  return responseSts;
};

/** Delete SKV 80 Order Based On ID */
export const handleSKV80OrdersDelete = async (
  rows,
  orderId,
  fetchSKV80OrdersData
) => {
  // Show confirmation dialog
  const row = rows.find((row) => row.order_id === orderId);
  // eslint-disable-next-line eqeqeq
  if (sessionStorage.getItem("userType") == "1") {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteValue = await axios.post(
            `${baseURL}/orders/deleteOrders`,
            {
              orderId: orderId,
              is_Delete: 1,
            }
          );
          if (deleteValue) {
            // If deletion is successful, show success message
            Swal.fire({
              title: "Deleted!",
              text: `${orderId} - Order Data Successfully Deleted`,
              icon: "success",
            });
            // Refresh data if needed
            fetchSKV80OrdersData();
          } else {
            // If deletion is not successful
            Swal.fire({
              title: "Error!",
              text: "No Response Comes From Backend",
              icon: "error",
            });
          }
        } catch (error) {
          // If an error occurs during deletion
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
          });
        }
      }
    });
  } else {
    // eslint-disable-next-line eqeqeq
    if (row.inserted_by == insertedBy()) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const deleteValue = await axios.post(
              `${baseURL}/orders/deleteOrders`,
              {
                orderId: orderId,
                is_Delete: 1,
              }
            );
            if (deleteValue) {
              // If deletion is successful, show success message
              Swal.fire({
                title: "Deleted!",
                text: `${orderId} - Order Data Successfully Deleted`,
                icon: "success",
              });
              // Refresh data if needed
              fetchSKV80OrdersData();
            } else {
              // If deletion is not successful
              Swal.fire({
                title: "Error!",
                text: "No Response Comes From Backend",
                icon: "error",
              });
            }
          } catch (error) {
            // If an error occurs during deletion
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
            });
          }
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You Can't Delete The Data",
      });
    }
  }
};

/** Delete User Data Based User ID*/
export const handleDeleteUser = async (userId, fetchedData) => {
  // Show confirmation dialog
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const deleteUser = await axios.post(`${baseURL}/users/deleteUsers`, {
          userId: userId,
          is_Delete: 1,
        });
        if (deleteUser) {
          // If deletion is successful, show success message
          Swal.fire({
            title: "Deleted!",
            text: "User Successfully Deleted",
            icon: "success",
          });
          // Refresh data if needed
          fetchedData();
        } else {
          // If deletion is not successful
          Swal.fire({
            title: "Error!",
            text: "Error While Deleting",
            icon: "error",
          });
        }
      } catch (error) {
        // If an error occurs during deletion
        Swal.fire({
          title: "Error!",
          text: error.message,
          icon: "error",
        });
      }
    }
  });
};

/** Delete Customer Data Based On Customer*/
export const handleCustomerDelete = async (rows, customer_id, fetchedData) => {
  // Show confirmation dialog
  const row = rows.find((row) => row.customer_id === customer_id);
  if (sessionStorage.getItem("userType") == "1") {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteValue = await axios.post(
            `${baseURL}/customers/deleteCustomer`,
            {
              customerId: customer_id,
              is_Delete: 1,
            }
          );
          if (deleteValue) {
            // If deletion is successful, show success message
            Swal.fire({
              title: "Deleted!",
              text: "Customer Data Successfully Deleted",
              icon: "success",
            });
            // Refresh data if needed
            fetchedData();
          } else {
            // If deletion is not successful
            Swal.fire({
              title: "Error!",
              text: "No Response Comes From Backend",
              icon: "error",
            });
          }
        } catch (error) {
          // If an error occurs during deletion
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
          });
        }
      }
    });
  } else {
    if (row.inserted_by == insertedBy()) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const deleteValue = await axios.post(
              `${baseURL}/customers/deleteCustomer`,
              {
                customerId: customer_id,
                is_Delete: 1,
              }
            );
            if (deleteValue) {
              // If deletion is successful, show success message
              Swal.fire({
                title: "Deleted!",
                text: "Customer Data Successfully Deleted",
                icon: "success",
              });
              // Refresh data if needed
              fetchedData();
            } else {
              // If deletion is not successful
              Swal.fire({
                title: "Error!",
                text: "No Response Comes From Backend",
                icon: "error",
              });
            }
          } catch (error) {
            // If an error occurs during deletion
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
            });
          }
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You Can't Delete The Customer",
      });
    }
  }
};

/** Delete Order based On ID */
export const handleOrdersDelete = async (rows, orderId, fetchedData) => {
  // Show confirmation dialog
  const row = rows.find((row) => row.order_id === orderId);
  if (sessionStorage.getItem("userType") == "1") {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const deleteValue = await axios.post(
            `${baseURL}/orders/deleteOrders`,
            {
              orderId: orderId,
              is_Delete: 1,
            }
          );

          if (deleteValue.data.mStatus === 200) {
            // If deletion is successful, show success message
            Swal.fire({
              title: "Deleted!",
              text: `${orderId} - Order Data Successfully Deleted`,
              icon: "success",
            });
            // Refresh data if needed
            fetchedData();
          } else {
            // If deletion is not successful
            Swal.fire({
              title: "Error!",
              text: "No Response Comes From Backend",
              icon: "error",
            });
          }
        } catch (error) {
          // If an error occurs during deletion
          Swal.fire({
            title: "Error!",
            text: error.message,
            icon: "error",
          });
        }
      }
    });
  } else {
    if (row.inserted_by == insertedBy()) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const deleteValue = await axios.post(
              `${baseURL}/orders/deleteOrders`,
              {
                orderId: orderId,
                is_Delete: 1,
              }
            );
            if (deleteValue) {
              // If deletion is successful, show success message
              Swal.fire({
                title: "Deleted!",
                text: `${orderId} - Order Data Successfully Deleted`,
                icon: "success",
              });
              // Refresh data if needed
              fetchedData();
            } else {
              // If deletion is not successful
              Swal.fire({
                title: "Error!",
                text: "No Response Comes From Backend",
                icon: "error",
              });
            }
          } catch (error) {
            // If an error occurs during deletion
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
            });
          }
        }
      });
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "You Can't Delete The Data",
      });
    }
  }
};
