/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
//import "../../data.css";
import { useEffect, useState } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select, // Added Select component
  MenuItem, // Added MenuItem component
  FormControl, // Added FormControl component
  InputLabel, // Added InputLabel component
  useTheme,
  Autocomplete,
} from "@mui/material";
import RestoreTwoToneIcon from "@mui/icons-material/RestoreTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import { tokens } from "../../theme";
import Swal from "sweetalert2";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import "../../index.css";
import { editOptionAlert } from "../support/sweetAlert";
import { insertedBy } from "../support/sessionUserName";
import { baseURL } from "../support/baseURL";

export default function GstTable() {
  const [rows, setRows] = useState([]);
  const [searchValue, setSearchValue] = useState(""); // State variable for search value
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editedRow, setEditedRow] = useState(null);
  const [editedDescription, setEditedDescription] = useState("");
  const [editedQuantity, setEditedQuantity] = useState("");
  const [editedWages, setEditedWages] = useState("");
  const [editedWeight, setEditedWeight] = useState("");
  const [editedAmount, setEditedAmount] = useState("");
  const [editedOrderDate, setEditedOrderDate] = useState("");
  const [editedHsnCode, setEditedHsnCode] = useState("");
  const [editedStateGst, setEditedStateGst] = useState("");
  const [editedCentralGst, setEditedCentralGst] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [errorQuantity, setErrorQuantity] = useState("");
  const [errorWages, setErrorWages] = useState("");
  const [errorWeight, setErrorWeight] = useState("");
  const [customerOptions, setCustomerOptions] = useState([]);
  const [error, setError] = useState("");
  const [statusError, setErrorStatus] = useState("");

  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [orderStatusDialogOpen, setOrderStatusDialogOpen] = useState(false);

  const [otherDescription, setOtherDescription] = useState("");
  const [showOtherTextField, setShowOtherTextField] = useState(false);
  const [filteredCustomerName, setFilteredCustomerName] = useState("");

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value === "other") {
      setShowOtherTextField(true);

      // setNewCustomer({ ...newCustomer, description: otherDescription });
    } else {
      setShowOtherTextField(false);
      setNewCustomer({ ...newCustomer, description: value });
    }
  };

  const [newCustomer, setNewCustomer] = useState({
    description: "",
    quantity: "",
    wages: "",
    weight: "",
    customer_id: "",
    hsn_code: "",
    amount: "",
    state_gst: "",
    central_gst: "",
    created_by: insertedBy(),
    order_date: new Date().toISOString().split("T")[0], // Initialize with today's date
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "otherDescription") {
      // Update description when otherDescription changes
      //setNewCustomer({ ...newCustomer, description: value });
      setOtherDescription(value);
    } else {
      setNewCustomer({ ...newCustomer, [name]: value });
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleEditOpen = (row) => {
    if (sessionStorage.getItem("userType") == "1") {
      setEditedRow(row);
      setEditedDescription(row.description);
      setEditedQuantity(row.quantity);
      setEditedWages(row.wages);
      setEditedWeight(row.weight);
      setEditedHsnCode(row.hsn_code);
      setEditedStateGst(row.state_gst);
      setEditedCentralGst(row.central_gst);
      setEditedOrderDate(row.order_date);
      setEditedAmount(row.amount);
      setEditOpen(true);
    } else {
      if (row.inserted_by == insertedBy()) {
        setEditedRow(row);
        setEditedDescription(row.description);
        setEditedQuantity(row.quantity);
        setEditedWages(row.wages);
        setEditedWeight(row.weight);
        setEditedHsnCode(row.hsn_code);
        setEditedStateGst(row.state_gst);
        setEditedCentralGst(row.central_gst);
        setEditedOrderDate(row.order_date);
        setEditedAmount(row.amount);
        setEditOpen(true);
      } else {
        editOptionAlert();
      }
    }
  };

  const handleEditClose = () => {
    setEditOpen(false);
  };

  const handleAddCustomer = async () => {
    if (
      !newCustomer.quantity ||
      !newCustomer.wages ||
      !newCustomer.weight ||
      !newCustomer.order_date
    ) {
      if (newCustomer.quantity === "") {
        setErrorQuantity("Please Enter The Quantity");
      }
      if (newCustomer.wages === "") {
        setErrorWages("Please Enter The Wages");
      }
      if (newCustomer.weight === "") {
        setErrorWeight("Please Enter The Weight");
      }
    } else {
      try {
        const addResponse = await axios.post(
          `${baseURL}/gstOrders/addGstOrders`,
          newCustomer
        );
        if (addResponse.data.mStatus === 400) {
          alert("There is a Duplicate Data, Please Enter The Unique Values");
          //console.log(addResponse.data.mData.sqlMessage);
        }
        // Refresh the table after adding the customer
        if (addResponse.data.mStatus === 200) {
          alert("Orders Data Added Successfully");
          fetchedData();
          // setNewCustomer({
          //   description: "",
          //   quantity: "",
          //   wages: "",
          //   weight: "",
          //   customer_id: "",
          //   order_date: "",
          //   amount: "",
          //   hsn_code: "",
          //   state_gst: "",
          //   central_gst: "",
          //   inserted_by: insertedBy(),
          // });
          // setOtherDescription("");
          setShowOtherTextField(false);
          //setOpenModal(false);
        }
        fetchedData();
        //handleCloseModal();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getRowClassName = (params) => {
    return "customRow"; // Return the class name for row styling
  };

  useEffect(() => {
    fetchedData();
  }, []);

  const fetchedData = async () => {
    try {
      const response = await axios.get(`${baseURL}/gstOrders/getGstOrders`);
      if (response.data.mData) {
        const filteredRows = response.data.mData.filter(
          (row) => row.is_delete !== 1
        );
        const gstOrders = filteredRows.filter((row) => row.purity === "GST");
        setRows(gstOrders);
        localStorage.setItem("gstOrders", gstOrders.length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleFromDateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const filterRows = () => {
    let filteredRows = rows.filter((row) =>
      row.contact_person_name.toLowerCase().includes(searchValue.toLowerCase())
    );

    //let filteredRows = rows;

    if (fromDate && toDate) {
      const fromDateObj = new Date(fromDate + "T00:00:00"); // Ensure date format includes time for proper comparison
      const toDateObj = new Date(toDate + "T23:59:59");

      filteredRows = filteredRows.filter((row) => {
        const orderDate = new Date(row.order_date);
        return orderDate >= fromDateObj && orderDate <= toDateObj;
      });
    }

    return filteredRows;
  };

  const handleEditSubmit = async () => {
    try {
      // Construct the updated order object including order_id
      const updatedOrder = {
        order_date: editedOrderDate,
        gst_order_id: editedRow.gst_order_id,
        description: editedDescription,
        quantity: editedQuantity,
        wages: editedWages,
        weight: editedWeight,
        hsn_code: editedHsnCode,
        state_gst: editedStateGst,
        central_gst: editedCentralGst,
        amount: editedAmount,
        updated_by: insertedBy(),
        updated_at: new Date().toISOString().split("T")[0],
      };

      // Update the row with the edited data
      const updatedRows = rows.map((row) => {
        if (row.gst_order_id === editedRow.gst_order_id) {
          return {
            ...row,
            order_date: editedOrderDate,
            description: editedDescription,
            quantity: editedQuantity,
            wages: editedWages,
            weight: editedWeight,
            hsn_code: editedHsnCode,
            state_gst: editedStateGst,
            central_gst: editedCentralGst,
            amount: editedAmount,
            updated_by: insertedBy(),
            updated_at: new Date().toISOString().split("T")[0],
          };
        }
        return row;
      });
      setRows(updatedRows);

      // Send updated data to API
      const response = await axios.post(
        `${baseURL}/gstOrders/updateGstOrders`,
        updatedOrder
      );

      // Handle success or failure
      if (response.data.mStatus === 200) {
        alert("Order updated successfully");
      } else {
        alert("Failed to update order");
      }

      handleEditClose();
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const columns = [
    {
      field: "slno",
      headerName: "Sl. No",
      width: 80,
      headerClassName: "customHeader",
      renderCell: (params) => {
        return params.row.slno;
      },
    },
    {
      field: "contact_person_name",
      headerName: "Customer Name",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "description",
      headerName: "Ordered Product",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "quantity",
      headerName: "Quantity",
      width: 110,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "wages",
      headerName: "Wages",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "weight",
      headerName: "Weights",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
    },
    {
      field: "order_date",
      headerName: "Order Date",
      width: 120,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      valueFormatter: (params) => {
        // Extract the date part from the ISO string
        const date = new Date(params.value);
        return date.toLocaleDateString(); // Format the date as a string
      },
    },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      headerClassName: "customHeader",
      cellClassName: "customCell",
      renderCell: (params) => {
        return (
          <div>
            {/* Replace these with your desired icons */}
            <BorderColorIcon
              style={{ cursor: "pointer", marginRight: "5px" }}
              onClick={() => handleEditOpen(params.row)}
            />
            <DeleteIcon
              style={{ cursor: "pointer", marginRight: "5px" }}
              onClick={() => handleOrdersDelete(params.row.gst_order_id)}
            />
          </div>
        );
      },
    },
  ];

  // Define columns based on user type
  let column = [...columns];

  const handleOrdersDelete = async (gst_order_id) => {
    // Show confirmation dialog
    const row = rows.find((row) => row.gst_order_id === gst_order_id);
    if (sessionStorage.getItem("userType") == "1") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            const deleteValue = await axios.post(
              `${baseURL}/gstOrders/deleteGstOrders`,
              {
                gst_order_id: gst_order_id,
                is_Delete: 1,
              }
            );
            if (deleteValue) {
              // If deletion is successful, show success message
              Swal.fire({
                title: "Deleted!",
                text: `${gst_order_id} - Gst Order Data Deleted!`,
                icon: "success",
              });
              // Refresh data if needed
              fetchedData();
            } else {
              // If deletion is not successful
              Swal.fire({
                title: "Error!",
                text: "No Response Comes From Backend",
                icon: "error",
              });
            }
          } catch (error) {
            // If an error occurs during deletion
            Swal.fire({
              title: "Error!",
              text: error.message,
              icon: "error",
            });
          }
        }
      });
    } else {
      if (row.inserted_by == insertedBy()) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              const deleteValue = await axios.post(
                `${baseURL}/gstOrders/deleteGstOrders`,
                {
                  gst_order_id: gst_order_id,
                  is_Delete: 1,
                }
              );
              if (deleteValue) {
                // If deletion is successful, show success message
                Swal.fire({
                  title: "Deleted!",
                  text: `${gst_order_id} - Order Data Successfully Deleted`,
                  icon: "success",
                });
                // Refresh data if needed
                fetchedData();
              } else {
                // If deletion is not successful
                Swal.fire({
                  title: "Error!",
                  text: "No Response Comes From Backend",
                  icon: "error",
                });
              }
            } catch (error) {
              // If an error occurs during deletion
              Swal.fire({
                title: "Error!",
                text: error.message,
                icon: "error",
              });
            }
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You Can't Delete The Data",
        });
      }
    }
  };

  // Function to fetch customer data
  const fetchCustomerData = async () => {
    try {
      const response = await axios.get(`${baseURL}/customers/getCustomer`);
      if (response.data.mData) {
        const activeCustomers = response.data.mData.filter(
          (data) => data.is_delete !== 1
        );
        const gstCustomers = activeCustomers.filter(
          (data) => data.purity == "GST"
        );
        //setCustomerOptions(response.data.mData);
        setCustomerOptions(gstCustomers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCustomerData(); // Fetch customer data on component mount
  }, []);

  const handleCancel = () => {
    setOrderStatusDialogOpen(false);
    setError("");
  };

  const handleStatusCancel = () => {
    setStatusDialogOpen(false);
    setErrorStatus("");
  };

  const currentDate = () => {
    let currentDate = new Date();

    // Get the current day, month, and year
    let day = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();

    // Format the date as desired
    let formattedDate = day + "-" + month + "-" + year;
    return formattedDate;
  };

  const userName = () => {
    return sessionStorage.getItem("LoggedInUserName");
  };

  const selectedCustomerName =
    customerOptions.find(
      (customer) => customer.customer_id === newCustomer.customer_id
    )?.contact_person_name || "";

  const generateReceipt = () => {
    // Check if a customer is selected
    // if (!searchValue.trim()) {
    //   console.error("No customer name provided");
    //   return;
    // }

    // const processedDate = (date) => {
    //   if (date == null) {
    //     return "0000-00-00";
    //   }
    //   const formattedDate = date.split("T")[0] || date;
    //   return formattedDate;
    // };

    const filteredRows = filterRows().filter(
      (row) =>
        row.contact_person_name.toLowerCase() === searchValue.toLowerCase()
    );

    // Check if there's any data for the selected customer
    if (filteredRows.length === 0) {
      Swal.fire({
        title: "Warning!",
        text: "Please Select The Customer For Print",
        icon: "warning",
      });
      return;
    }

    const filteredRow = filteredRows.filter((item) => item.is_delete === 0);

    // filteredRow.forEach((item) => {
    //   item.order_date = processedDate(item.order_date);
    // });

    filteredRow.sort((a, b) => {
      const dateA = new Date(a.order_date);
      const dateB = new Date(b.order_date);
      return dateA - dateB;
    });

    // Generate the receipt content
    const receiptContent = filteredRow.map((item) => {
      const orderDate = new Date(item.order_date);

      // Extract day, month, and year components
      const day = orderDate.getDate();
      const month = orderDate.getMonth() + 1; // Month is zero-based, so add 1
      const year = orderDate.getFullYear();

      // Ensure day and month are formatted with leading zeros if necessary
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;

      // Concatenate components to form the desired date format
      const formattedDate = formattedDay + "-" + formattedMonth + "-" + year;

      // Calculate state GST as 1.5% of the amount
      // const stateGST = item.amount * 0.015;
      const stateGST =
        parseInt(item.amount) * (parseFloat(item.state_gst) / 100);

      const centralGst =
        parseInt(item.amount) * (parseFloat(item.central_gst) / 100);

      // Calculate total amount
      const totalAmount = parseInt(item.amount) + stateGST + centralGst;

      return {
        order_id: item.gst_order_id,
        product: item.description,
        weight: item.weight,
        amount: item.amount,
        state_gst: item.state_gst,
        stateGST: stateGST,
        centralGst: centralGst,
        central_gst: item.central_gst,
        orderDate: formattedDate, // Use the formatted date
        totalAmount: totalAmount.toFixed(2),
        hsnCode: item.hsn_code,
      };
    });

    const totalWeight = receiptContent
      .reduce((total, item) => total + parseFloat(item.weight), 0)
      .toFixed(3);

    // Create a new window for printing
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
    <html>
      <head>
      <title>Print Receipt</title>
        <style>
          /* Add your custom styles for the receipt here */
          body {
            font-family: Arial, sans-serif;
            font-size : 14px
          }
          table {
            border-collapse: collapse;
            width: 80%;
            font-size : 14px
          }
          th, td {
            border: 1px solid black;
            padding: 8px;
            text-align: left;
          }
          th {
            background-color: #f2f2f2;
          }
          td:nth-child(1){
            width : 7%,
            display : flex,
            flex-wrap : nowrap,
          }
          td:nth-child(2){
            width : 10%
          }
          td:nth-child(3){
            width : 5%
          }
          button {
            margin-top: 20px;
            padding: 10px 20px;
            background-color: #4caf50;
            color: white;
            border: none;
            cursor: pointer;
          }
          button:hover {
            background-color: #45a049;
          }
          h3,h4,p,h1{
            text-align:center;
          }
          .spacing{
            margin : 1px
          }
        </style>
      </head>
      <body>
      <h3 class="spacing">SKV Silvers</h3>
      <p class="spacing">+91 6383887488, Ramanathapuram</p>
      <h3 style="margin-top : 3px">${searchValue}</h3>
      <div style="display : flex; justify-content : space-between ; margin-top : -20px">
      <h5>Date: ${currentDate()}</h5>
      <h5>Receipted By: ${userName()}</h5>
      </div>
        <table>
            <tr>
              <th>Order Date</th>
              <th>Description</th>
              <th>Weight</th>
              <th>Amount</th>
              <th>SGST:${receiptContent[0].state_gst}%</th>
              <th>CGST:${receiptContent[0].central_gst}%</th>
              <th>Total Amount</th>
            </tr>
          <tbody>
            ${receiptContent
              .map(
                (item) => `
              <tr>
                <td>${item.orderDate}</td>
                <td>${item.product}</td>
                <td>${item.weight}</td>
                <td>₹${item.amount}</td>
                <td>₹${item.stateGST.toFixed(3)}</td>
                <td>₹${item.centralGst.toFixed(3)}</td>
                <td>₹${parseInt(item.totalAmount)}</td>
              </tr>
            `
              )
              .join("")}
          <tr>
          <td colspan="6" style="text-align: right;">Final Amount</td>
          <td>₹${parseInt(
            receiptContent
              .reduce((acc, item) => acc + parseFloat(item.totalAmount), 0)
              .toFixed(2)
          )}</td>
        </tr>
        <tr>
        <td colspan="6" style="text-align: right;">Total Weight</td>
        <td>${totalWeight}</td>
      </tr> 
            <tr>
            <td colspan="6" style="text-align: right;">OB:</td>
            <td></td>
          </tr> 
          <tr>
            <td colspan="6" style="text-align: right;">HSN Code:</td>
            <td>${receiptContent[0].hsnCode}</td>
          </tr> 
          </tbody>
        </table>
        <h3 style="text-align: left;">Mode Of Payment:   </h3>
        <h4 style="text-align: left;">Party's Signature:   </h4>
        <p style="text-align: left;">---------------------------</p>
        <h3>Thank You</h3>
      </body>
    </html>
  `);
    // Call the print function after the document is written
    printWindow.document.close();
    printWindow.print();

    // Call the ownerReceipt function immediately
    // ownerReceipt();

    // Close the window if it's not null
    if (printWindow) {
      printWindow.close();
    }
  };

  const ownerReceipt = () => {
    // Generate the receipt content

    // const reviewedData = filteredCustomerOrderedData.filter(
    //   (item) => item.is_reviewed == 1
    // );

    const filteredData = filterRows().filter((item) => item.is_delete === 0);

    // filteredData.sort((a, b) => {
    //   const dateA = new Date(processedDate(a.order_date));
    //   const dateB = new Date(processedDate(b.order_date));
    //   return dateA - dateB;
    // });

    const receiptContent = filteredData.map((item) => {
      const OD = item.order_date == null ? "0000-00-00" : item.order_date;
      const orderDate = new Date(OD);

      // Extract day, month, and year components
      const day = orderDate.getDate();
      const month = orderDate.getMonth() + 1; // Month is zero-based, so add 1
      const year = orderDate.getFullYear();

      // Ensure day and month are formatted with leading zeros if necessary
      const formattedDay = day < 10 ? "0" + day : day;
      const formattedMonth = month < 10 ? "0" + month : month;

      // Concatenate components to form the desired date format
      const formattedDate = formattedDay + "-" + formattedMonth + "-" + year;

      return {
        order_id: item.gst_order_id,
        product: item.description,
        weight: item.weight,
        orderDate: formattedDate, // Use the formatted date
      };
    });

    const totalWeight = receiptContent
      .reduce((total, item) => total + parseFloat(item.weight), 0)
      .toFixed(3);

    // Create a new window for printing
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`<html>
    <head>
    <style>
    /* Add your custom styles for the receipt here */
    body {
      font-family: Arial, sans-serif;
      font-size : 14px
    }
    table {
      margin-left : 2px;
      border-collapse: collapse;
      width: 85%;
      font-size : 14px
    }
    th, td {
      border: 1px solid black;
      padding: 5px;
      text-align: left;
    }
    th {
      font-size : 12px;
      background-color: #f2f2f2;
    }
    td:nth-child(1){
      width : 3%
    }
    td:nth-child(2){
      width : 15%,
      display : flex,
      flex-wrap : nowrap,
    }
    td:nth-child(3){
      width : 13%
    }
    td:nth-child(4){
      width : 5%
    }
    button {
      margin-top: 20px;
      padding: 10px 20px;
      background-color: #4caf50;
      color: white;
      border: none;
      cursor: pointer;
    }
    button:hover {
      background-color: #45a049;
    }
    h3,h4,p,h1{
      text-align:center;
    }
    .spacing{
      margin : 1px
    }
  </style>
    </head>
    <body>
      <h3 class="spacing">SKV Silvers</h3>
      <p class="spacing">+91 6383887488, Ramanathapuram</p>
      <h3 style="margin-top : 3px">${selectedCustomerName}</h3>
      <div style="display : flex; justify-content : space-between ; margin-top : -20px">
      <h5>Date: ${currentDate()}</h5>
      <h5>Receipted By: ${userName()}</h5>
      </div>
      <table>
          <tr>
            <th>Order ID</th>
            <th>Order Date</th>
            <th>Details</th>
            <th>Weight</th>
          </tr>
        <tbody>
          ${receiptContent
            .map(
              (item) => `
            <tr>
              <td>${item.order_id}</td>
              <td style="font-size : 12px">${item.orderDate}</td>
              <td>${item.product}</td>
              <td>${item.weight}</td>
            </tr>
          `
            )
            .join("")}
            <tr>
            <td colspan="3" style="text-align: right;">Total Weight:</td>
            <td>${totalWeight}</td>
          </tr> 
          <tr>
          <td colspan="3" style="text-align: right;">OB:</td>
          <td></td>
        </tr> 
        </tbody>
      </table>
      <h3>Thank You</h3>
      <p>Own Copy</p>
    </body>
  </html>
    `);

    // Call the print function after the document is written
    printWindow.document.close();
    printWindow.print();
  };

  const getUniqueContactPersonNames = (rows) => {
    const uniqueNames = [];
    const nameSet = new Set();

    rows.forEach((customer) => {
      if (!nameSet.has(customer.contact_person_name)) {
        nameSet.add(customer.contact_person_name);
        uniqueNames.push(customer);
      }
    });

    return uniqueNames;
  };

  return (
    <Box sx={{ height: 500, width: "97%", marginX: "2%" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Button
          onClick={handleOpenModal}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            height: "50px",
          }}
        >
          <PeopleAltTwoToneIcon sx={{ mr: "10px" }} />
          Add Orders
        </Button>
        <FormControl margin="dense">
          <InputLabel id="demo-simple-select-label">Customer</InputLabel>
          <Select
            margin="dense"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            // Use the correct name for the field
            value={searchValue} // Use the correct state value
            onChange={handleSearchChange} // Use the common input change handler
            required
            //style={{ marginBottom: 20 }}
            sx={{ width: "200px" }}
          >
            {/* Map over the customerOptions array to dynamically generate MenuItem components */}
            {getUniqueContactPersonNames(rows).map((customer) => (
              //<MenuItem key={customer.customer_id} value={customer.contact_person_name}>
              <MenuItem
                key={customer.customer_id}
                value={customer.contact_person_name}
              >
                {customer.contact_person_name}{" "}
                {/* Assuming customer_name is the property for displaying customer names */}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="From Date"
          type="date"
          variant="outlined"
          fullWidth
          value={fromDate}
          onChange={handleFromDateChange}
          sx={{ width: "200px" }}
          style={{ marginBottom: 20 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          label="To Date"
          type="date"
          variant="outlined"
          fullWidth
          value={toDate}
          onChange={handleToDateChange}
          sx={{ width: "200px" }}
          style={{ marginBottom: 20 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          style={{ height: "50px" }}
          variant="contained"
          color="primary"
          onClick={generateReceipt}
        >
          Print Receipt
        </Button>
        <Button
          // onClick={fetchedData}
          sx={{
            backgroundColor: colors.blueAccent[700],
            color: colors.grey[100],
            fontSize: "14px",
            fontWeight: "bold",
            padding: "10px 20px",
            height: "50px",
          }}
        >
          <RestoreTwoToneIcon sx={{ mr: "10px" }} />
          Refresh
        </Button>
      </div>
      {/** Add Popup component */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <PeopleAltTwoToneIcon sx={{ mr: "20px" }} />
        <DialogTitle>Add New GST Orders</DialogTitle>
        <DialogContent>
          {/* <FormControl rol fullWidth>
            <InputLabel id="demo-simple-select-label">Customer</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              margin="dense"
              name="customer_id" // Use the correct name for the field
              value={newCustomer.customer_id} // Use the correct state value
              onChange={handleInputChange} // Use the common input change handler
              required
            >
              {customerOptions.map((customer) => (
                <MenuItem
                  key={customer.customer_id}
                  value={customer.customer_id}
                >
                  {customer.contact_person_name}{" "}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <Autocomplete
            options={customerOptions}
            getOptionLabel={(option) => option.contact_person_name}
            onChange={(event, value) =>
              setNewCustomer({
                ...newCustomer,
                customer_id: value?.customer_id || "",
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Customer"
                fullWidth
                required
              />
            )}
          />

          <TextField
            margin="dense"
            label="Order Date"
            type="date"
            fullWidth
            required
            name="order_date"
            value={newCustomer.order_date}
            onChange={handleInputChange}
          />

          {/* <TextField
            autoFocus
            margin="dense"
            label="Description"
            fullWidth
            required
            name="description"
            value={newCustomer.description}
            onChange={handleInputChange}
          /> */}
          <FormControl fullWidth margin="dense">
            <InputLabel>Description</InputLabel>
            <Select
              name="description"
              value={newCustomer.description}
              required
              onChange={handleDescriptionChange}
            >
              <MenuItem value="M">METTI(M)</MenuItem>
              <MenuItem value="KA">KAAPU(KA)</MenuItem>
              <MenuItem value="K">KODI(K)</MenuItem>
              <MenuItem value="70">70</MenuItem>
              <MenuItem value="80">80</MenuItem>
              <MenuItem value="T">THANDAI(TH)</MenuItem>
              <MenuItem value="NT">NT</MenuItem>
              <MenuItem value="WS">WS</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
          {showOtherTextField && (
            <TextField
              margin="dense"
              label="Other Description"
              fullWidth
              required
              name="otherDescription"
              value={otherDescription}
              //onChange={(e) => setOtherDescription(e.target.value)}
              onChange={handleInputChange}
            />
          )}
          {/* <p style={{ color: "red" }}>{errorName}</p> */}
          <span style={{ color: "red" }}>{errorDescription}</span>
          <TextField
            margin="dense"
            label="Quantity"
            fullWidth
            name="quantity"
            value={newCustomer.quantity}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorQuantity}</span>
          <TextField
            autoFocus
            margin="dense"
            label="Wages"
            fullWidth
            required
            name="wages"
            value={newCustomer.wages}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorWages}</span>
          <TextField
            margin="dense"
            label="Weight"
            fullWidth
            required
            name="weight"
            value={newCustomer.weight}
            onChange={handleInputChange}
          />
          <span style={{ color: "red" }}>{errorWeight}</span>
          <TextField
            margin="dense"
            label="Amount"
            fullWidth
            required
            name="amount"
            value={newCustomer.amount}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="State GST"
            fullWidth
            required
            name="state_gst"
            value={newCustomer.state_gst}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="Central GST"
            fullWidth
            required
            name="central_gst"
            value={newCustomer.central_gst}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="HSN Code"
            fullWidth
            required
            name="hsn_code"
            value={newCustomer.hsn_code}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleAddCustomer}>Add</Button>
        </DialogActions>
      </Dialog>
      {/* Edit popup component */}
      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Orders</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Order Date"
            type="date"
            fullWidth
            required
            name="order_date"
            value={editedOrderDate}
            onChange={(e) => setEditedOrderDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
          {/* <TextField
            margin="dense"
            label="Description"
            fullWidth
            required
            name="description"
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
          /> */}
          <FormControl fullWidth margin="dense">
            <InputLabel>Description</InputLabel>
            <Select
              name="description"
              value={editedDescription}
              required
              onChange={(e) => setEditedDescription(e.target.value)}
            >
              <MenuItem value="M">METTI(M)</MenuItem>
              <MenuItem value="KA">KAAPU(KA)</MenuItem>
              <MenuItem value="K">KODI(K)</MenuItem>
              <MenuItem value="70">70</MenuItem>
              <MenuItem value="80">80</MenuItem>
              <MenuItem value="T">THANDAI(TH)</MenuItem>
              <MenuItem value="NT">NT</MenuItem>
              <MenuItem value="WS">WS</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>
          <TextField
            margin="dense"
            label="Quantity"
            fullWidth
            name="quantity"
            value={editedQuantity}
            onChange={(e) => setEditedQuantity(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Wages"
            fullWidth
            required
            name="wages"
            value={editedWages}
            onChange={(e) => setEditedWages(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Weight"
            fullWidth
            required
            name="weight"
            value={editedWeight}
            onChange={(e) => setEditedWeight(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Amount"
            fullWidth
            required
            name="amount"
            value={editedAmount}
            onChange={(e) => setEditedAmount(e.target.value)}
          />
          <TextField
            margin="dense"
            label="HSN Code"
            fullWidth
            required
            name="hsn_code"
            value={editedHsnCode}
            onChange={(e) => setEditedHsnCode(e.target.value)}
          />
          <TextField
            margin="dense"
            label="State GST"
            fullWidth
            required
            name="state_gst"
            value={editedStateGst}
            onChange={(e) => setEditedStateGst(e.target.value)}
          />
          <TextField
            margin="dense"
            label="State GST"
            fullWidth
            required
            name="central_gst"
            value={editedCentralGst}
            onChange={(e) => setEditedCentralGst(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={handleEditSubmit}>Save</Button>
        </DialogActions>
      </Dialog>

      <DataGrid
        rows={filterRows().map((row, index) => ({ ...row, slno: index + 1 }))}
        columns={column}
        getRowClassName={getRowClassName} // Apply row styling
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        getRowId={(row) => row.gst_order_id}
      />
    </Box>
  );
}
