/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
// ForgotPassword.js
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SignIn from "../form/Login";
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Swal from "sweetalert2";
import { baseURL } from "../support/baseURL";

const defaultTheme = createTheme();

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [showTextField, setShowTextField] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [password, setPassword] = useState("");
  const [repeat_password, setRepeatPassword] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorRepeatPassword, setErrorRepeatPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (email === "" || email === undefined || email === null) {
      setError("Please Enter The Email Address");
    } else {
      try {
        const response = await axios.post(`${baseURL}/users/checkEmail`, {
          email: email,
        });
        switch (response.data.mStatus) {
          case 200:
            handleShowTextField();
            break;
          case 202:
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Invalid Email Id, Kindly Please Check",
            });
            break;
        }
      } catch (error) {
        console.log(error.message);
      }
      setError("");
      if (password === "" || password === undefined || password === null) {
        //setErrorPassword("Please Enter The Password");
        alert("Please Enter The password");
      }
      if (
        repeat_password === "" ||
        repeat_password === undefined ||
        repeat_password === null
      ) {
        //setErrorRepeatPassword("Please Enter The Repeat Password");
        alert("Please Repeat Your Password");
      }
      if (password && repeat_password) {
      }
      if (
        password !== "" &&
        repeat_password !== "" &&
        password === repeat_password
      ) {
        try {
          const response = await axios.post(`${baseURL}/users/forgotPassword`, {
            email: email,
            password: password,
          });
          switch (response.data.mStatus) {
            case 200:
              alert("Password Resetted Successfully");
              break;
            case 402:
              alert("Password Reset Failed");
              break;
          }
        } catch (error) {
          console.log(error.message);
        }
      }
    }
  };

  const handleShowTextField = () => {
    setShowTextField(true);
  };

  const handleLoginNav = () => {
    setShowLogin(true);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      {!showLogin && (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error && <span style={{ color: "red" }}>{error}</span>}
              {showTextField && (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="New Password"
                    name="password"
                    autoComplete="password"
                    type={showPassword ? "text" : "password"}
                    autoFocus
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />
                  {errorPassword && (
                    <span style={{ color: "red" }}>{errorPassword}</span>
                  )}
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Repeat Password"
                    name="repeat_password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="password"
                    autoFocus
                    value={repeat_password}
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={togglePasswordVisibility}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      ),
                    }}
                  />
                  {errorRepeatPassword && (
                    <span style={{ color: "red" }}>{errorRepeatPassword}</span>
                  )}
                </>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Reset Password
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link
                    component={RouterLink}
                    to="/login"
                    variant="body2"
                    onClick={handleLoginNav}
                  >
                    Remember your password? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      )}
      {showLogin && <SignIn />}
    </ThemeProvider>
  );
};

export default ForgotPassword;
