/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";
import { decryptData } from "../support/encryption";

function Copyright(props) {
  return (
    <></>
    // <Typography variant="body2" color="text.secondary" align="center" {...props}>
    //   {"Copyright © "}
    //   <Link color="inherit" href="https://www.office.com/">
    //     SKV Silvers APPLICATION
    //   </Link>{" "}
    //   {new Date().getFullYear()}
    //   {"."}
    // </Typography>
  );
}

const defaultTheme = createTheme();

export default function PasswordReset({ handleLogin }) {
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [repeatPasswordError, setRepeatPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    if (!data.get("password")) {
      setPasswordError("Please Enter The Password");
    }
    if (!data.get("repeat_password")) {
        setRepeatPasswordError("Please Enter The Repeat Password");
    }
    if (data.get("password") != data.get("repeat_password")) {
      alert("Password And Repeat Password Is MisMatching");
    }
    
  };

  const ScrollingText = styled("div")({
    display: "inline-block",
    whiteSpace: "nowrap",
    animation: "scroll 15s linear infinite",
    "@keyframes scroll": {
      "0%": {
        transform: "translateX(100%)",
      },
      "100%": {
        transform: "translateX(-100%)",
      },
    },
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <ScrollingText style={{ color: "red" }}>
          எச்சரிக்கையாக இருங்கள், நீங்கள் கடவுச்சொல்லை மாற்றினால், அதனுடன்
          ரகசியமாக இருங்கள், அதை யாரிடமும் பகிர வேண்டாம்
        </ScrollingText>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Password Reset
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              value={ decryptData(sessionStorage.getItem("LoggedInPhn"))}
              fullWidth
              id="email"
              //disabled="true"
              label="Phone Number"
              name="email"
              autoComplete="email"
              autoFocus
              InputProps={{
                readOnly: true,
              }}
            />
            <p style={{ color: "red" }}>{emailError}</p>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
            <p style={{ color: "red" }}>{passwordError}</p>
            <TextField
              margin="normal"
              required
              fullWidth
              name="repeat_password"
              label="Repeat Password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
            />
            <p style={{ color: "red" }}>{repeatPasswordError}</p>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Reset Password
            </Button>
            <Grid container>
              <Grid item xs>
                {/* <Link component="button" variant="body2" onClick={handleForgotPasswordClick} disabled={showForgotPassword}>
                    Forgot Password
                  </Link> */}
              </Grid>
              <Grid item></Grid>
            </Grid>
          </Box>
        </Box>

        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
