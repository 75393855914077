import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import GstTable from "../DataGrid/GstTable";

const GstOrderRegPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box m="20px">
      <Header title="GST Order Registration" subtitle="Manage The GST Order Registration" />
      <GstTable />
    </Box>
  );
};

export default GstOrderRegPage;
