import { Box, IconButton, useTheme } from "@mui/material";
import { useContext, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { decryptData } from "../support/encryption";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { styled } from "@mui/system";
import { grey } from "@mui/material/colors";
import Badge from '@mui/material/Badge';

const Topbar = ({ onSignOut }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const navigate = useNavigate();

  const [anchor, setAnchor] = useState(null);

  const handleClick = (event) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? "simple-popper" : undefined;

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleSignOut = () => {
    onSignOut(); // Call the sign-out function passed from App component
    navigate("/login"); // Navigate to the login page
  };

  const fetchUserName = () => {
    return decryptData(sessionStorage.getItem("LoggedInUserName"));
  };

  const fetchPhnNumber = () => {
    return decryptData(sessionStorage.getItem("LoggedInPhn"));
  };

  const fetchEmail = () => {
    return decryptData(sessionStorage.getItem("email"));
  };

  const PopupBody = styled("div")(
    ({ theme }) => `
    width: max-content;
    padding: 12px 16px;
    margin: 8px;
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    background-color: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    box-shadow: ${
      theme.palette.mode === "dark"
        ? `0px 4px 8px rgb(0 0 0 / 0.7)`
        : `0px 4px 8px rgb(0 0 0 / 0.1)`
    };
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    z-index: 1;
  `
  );

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        {/* <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton> */}
        <IconButton aria-label="cart">
          <StyledBadge badgeContent={1} color="primary">
            <NotificationsOutlinedIcon  />
          </StyledBadge>
        </IconButton>
        <IconButton onClick={handleDialogOpen}>
          <LogoutIcon />
        </IconButton>
        {/* <IconButton onClick={handleProfileOpen}>
          <PersonOutlinedIcon />
        </IconButton> */}
        <IconButton aria-describedby={id} type="button" onClick={handleClick}>
          <PersonOutlinedIcon />
        </IconButton>
      </Box>
      {/* Sign Out Dialog */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Sign Out</DialogTitle>
        <DialogContent>Are you sure you want to sign out?</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button color="error" onClick={handleSignOut}>
            Sign Out
          </Button>
        </DialogActions>
      </Dialog>
      {/**Profile Dialog */}
      {/* <Dialog open={profileDialogOpen} onClose={handleProfileClose}>
        <DialogTitle>
          <Avatar alt="Profile Image" />
          Hello {fetchUserName()}!!
        </DialogTitle>
        <DialogContent>Phone Number - {fetchPhnNumber()}</DialogContent>
        <DialogContent>Email Id - {fetchEmail()}</DialogContent>

        <DialogActions>
          <Button onClick={handleProfileClose}>Cancel</Button>
        </DialogActions>
      </Dialog> */}

      <BasePopup id={id} open={open} anchor={anchor}>
        <PopupBody>Phone - {fetchPhnNumber()}</PopupBody>
        <PopupBody>Email - {fetchEmail()}</PopupBody>
      </BasePopup>
    </Box>
  );
};

export default Topbar;
